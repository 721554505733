import styled from '@emotion/styled'
import cx from 'classnames'

import type { Ads as AdsModel } from './Advertising.model'
import { getSlotId } from './utils'

export interface AdsProps extends Omit<AdsModel, 'url'> {
  className?: string
}

const AdsStyles = styled.div<Pick<AdsModel, 'type'>>((props) => ({
  textAlign: 'center',
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  height: '312px',
  flexDirection: 'column',
  '&:not(:empty)': {
    backgroundColor: '#e5ebef',
    borderRadius: 'var(--radii-default)',
    padding: '0.5rem 0',
    overflow: 'hidden',

    '&:before': {
      content: `'Publicidade'`,
      display: 'flex',
      justifyContent: 'center',
      margin: '0.5rem 0 ',
      textTransform: 'uppercase',
      opacity: 0.33,
      fontSize: '0.75rem',
    },
  },

  ...(props.type.match(/(middle)/) && {
    maxWidth: '25rem',
  }),
}))

export const Ads = ({ adUnitSlotId, type, className }: AdsProps) => {
  const slotId = getSlotId(adUnitSlotId)
  return <AdsStyles id={slotId} className={cx(className)} type={type} />
}

export default Ads
