import { BuilderComponent } from '@builder.io/react'
import type { BuilderContent as Content } from '@builder.io/sdk'

import { BUILDER_ANNOUNCEMENT_BAR_MODEL } from '@/legacy/config/builder'

interface AnnouncementBarBlockProps {
  content?: Content
}

const AnnouncementBarBlock = ({ content }: AnnouncementBarBlockProps) => (
  <BuilderComponent model={BUILDER_ANNOUNCEMENT_BAR_MODEL} content={content} />
)

export default AnnouncementBarBlock
