import { BuilderContent, useIsPreviewing } from '@builder.io/react'
import type { GetServerSidePropsContext, InferGetServerSidePropsType } from 'next'

import { AnnouncementBarBlock } from '@/legacy/blocks/AnnouncementBar'
import { FooterBlock } from '@/legacy/blocks/Footer'
import { HeaderBlock } from '@/legacy/blocks/Header'
import { BuilderAds } from '@/legacy/components/Advertising'
import { getAppInstallBannerCookie } from '@/legacy/components/AppInstallBanner/helpers'
import { Metatags } from '@/legacy/components/Metatags'
import { PageLayout } from '@/legacy/components/PageLayout'
import { Theme } from '@/legacy/components/Theme'
import { initBuilder } from '@/legacy/config/builder'

import { AnalyticsProvider } from '@/legacy/core/analytics'
import type { SampleRate } from '@/legacy/core/monitoring/service'
import { getDevice } from '@/legacy/core/utils/getDevice'
import type { AnalyticsData } from '@/legacy/models'
import { isNotFound } from '@/legacy/server/core/builder/pageNotFound'
import { setCacheControlPage } from '@/legacy/server/core/builder/setCacheControlPage'
import { setNoCacheControl } from '@/legacy/server/core/utils/cacheControl'
import { getBuilderPageProps } from '@/legacy/server/services/builder/page'
import { hasRolloutKeyOnHeaders } from '@/server/rollout-key-persistence'

import '@/legacy/blocks/register'
import '@/ui/registry'

initBuilder()

export async function getServerSideProps({
  res,
  req,
}: GetServerSidePropsContext<{ page?: string[] }>) {
  const BASE_PATH = process.env.BASE_PATH || ''
  const urlPath = `${BASE_PATH}/`

  const sampleRates: SampleRate = {
    rum: Number(process.env.DALVITO_DD_RUM_SAMPLE_RATE),
    sessionReplay: Number(process.env.DALVITO_DD_SESSION_REPLAY_SAMPLE_RATE),
  }

  const appInstallBanner = getAppInstallBannerCookie(req)

  const device = getDevice(req.headers)

  const props = await getBuilderPageProps({ res, req, urlPath })

  if (!hasRolloutKeyOnHeaders(req)) {
    setCacheControlPage({ page: props.page, res, req })
  } else {
    setNoCacheControl(res)
  }

  return {
    notFound: isNotFound({ page: props.page, req }),
    props: {
      ...props,
      sampleRates,
      appInstallBanner,
      device,
    },
  }
}

export default function Page({
  page,
  model,
  footer,
  announcementBar,
  adsBottom,
  isApp,
  appInstallBanner,
}: InferGetServerSidePropsType<typeof getServerSideProps>) {
  const isPreviewing = useIsPreviewing()
  const hasPage = page && !isPreviewing

  return (
    <BuilderContent
      model={model}
      options={{ includeRefs: true }}
      {...(hasPage && { content: page })}
    >
      {(data, loading, content) => {
        if (loading) {
          return null
        }

        const url = data?.url
        const analytics: AnalyticsData = data?.analytics

        return (
          <Theme data={data}>
            <Metatags data={data} />
            {!isApp && (
              <AnalyticsProvider event={{ ...analytics, url, position: 'topo' }}>
                <AnnouncementBarBlock content={announcementBar} />
                <HeaderBlock data={{ ...data, appInstallBanner }} />
              </AnalyticsProvider>
            )}
            <AnalyticsProvider event={{ ...analytics, url, position: 'meio' }}>
              <PageLayout content={content} />
              <BuilderAds adsContent={adsBottom} />
            </AnalyticsProvider>
            {!isApp && (
              <AnalyticsProvider event={{ ...analytics, url, position: 'rodape' }}>
                <FooterBlock content={footer} />
              </AnalyticsProvider>
            )}
          </Theme>
        )
      }}
    </BuilderContent>
  )
}
