import { getPageUrl, hasWindow } from '@/legacy/core/utils/dom/utils'

import { getProductFromUrl } from '@/legacy/core/utils/getProductFromUrl'

import type { Ads, AdsSizeType } from './Advertising.model'

import { ADS_SIZE_MAP, PARENT_AD_UNIT, SCRIPT_ID } from './constants'

type Slot = googletag.Slot & {
  timeout?: ReturnType<typeof setTimeout>
}

const readyAdvertising = (callback: () => void) => {
  try {
    window.googletag.cmd.push(() => {
      callback()
    })
  } catch (error) {
    console.error('Erro ao iniciar o banner', error)
  }
}

const defineAdSizing = (type: AdsSizeType) => {
  const sizeMap = window.googletag.sizeMapping()
  const sizeDefinition = ADS_SIZE_MAP[type]

  for (const [viewport, sizes] of sizeDefinition) {
    sizeMap.addSize(viewport as googletag.SingleSizeArray, sizes)
  }

  return sizeMap.build()
}

const defineSlot = ({ adUnitSlotId, type, url }: Ads) => {
  const { fullPath, slotId, sizeMapping } = getAdsProps({ adUnitSlotId, type, url })

  const hasAlreadyBeenDefined = window.gptadslots[slotId]
  if (hasAlreadyBeenDefined) return

  const slot = window.googletag
    .defineSlot(
      fullPath,
      [
        [0, 0],
        [320, 50],
      ],
      slotId,
    )
    .defineSizeMapping(sizeMapping)

  return slot
}

const refreshAdServer = (event: googletag.events.Event) => {
  const slot: Slot = event.slot

  if (slot.getTargeting('refresh').indexOf('true') === -1) {
    return
  }

  if (slot.timeout) {
    clearTimeout(slot.timeout)
  }

  slot.timeout = setTimeout(() => {
    slot.setTargeting('refreshed_slot', 'true')
    window.googletag.pubads().refresh([slot])
    slot.timeout = undefined
  }, 30000)
}

const checkHasAdvertisingScript = () => {
  const hasAdvertisingScript =
    window && window.document && window.document.getElementById(SCRIPT_ID)
  return hasAdvertisingScript
}

const loadScriptGPTAds = (callback?: () => void) => {
  enableGtmServices()
  createGtmScriptOnHeadElem(callback)
}

const enableGtmServices = () => {
  if (!hasWindow()) return

  const hasGtmBypassQueryString = window.location.search.indexOf('gtm=f') > -1
  const hasAdvertisingScript = checkHasAdvertisingScript()
  const gt = window.googletag

  if (hasAdvertisingScript || hasGtmBypassQueryString || !(gt && gt.cmd)) {
    return
  }

  gt.cmd.push(() => {
    gt.pubads().collapseEmptyDivs()
    gt.pubads().set('page_url', getPageUrl())
    gt.pubads().setCentering(true)
    gt.pubads().addEventListener('impressionViewable', refreshAdServer)
    gt.pubads().enableLazyLoad({
      fetchMarginPercent: 100,
      renderMarginPercent: 100,
      mobileScaling: 2.0,
    })

    gt.enableServices()
  })
}

const createGtmScriptOnHeadElem = (callback?: () => void) => {
  const hasAdvertisingScript = checkHasAdvertisingScript()
  if (hasAdvertisingScript) return
  const head = document.getElementsByTagName('head').item(0)
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.id = SCRIPT_ID
  script.onload = () => callback && callback()
  script.async = true
  script.src = 'https://www.googletagservices.com/tag/js/gpt.js'

  head?.appendChild(script)
}

export const destroyAdSlot = (id: string) => {
  readyAdvertising(() => {
    try {
      const slot = window.gptadslots[id]
      if (slot && slot.timeout) {
        clearTimeout(slot.timeout)
        slot.timeout = null
      }

      window.googletag.destroySlots([slot])
      delete window.gptadslots[id]
    } catch (error) {
      throw new Error('Erro ao destruir banner')
    }
  })
}

const isFullPath = (adUnitSlotId: string) => adUnitSlotId.match(/^\/[0-9]+\/.*/)

const getFullPath = ({ adUnitSlotId, url }: Record<'adUnitSlotId' | 'url', string>) => {
  if (isFullPath(adUnitSlotId)) {
    return adUnitSlotId
  }

  const productKey = getProductFromUrl(url)
  const parentAdUnit = PARENT_AD_UNIT[productKey]
  const fullPath = `/281426761/${parentAdUnit}/${adUnitSlotId}`

  return fullPath
}

export const getSlotId = (adUnitSlotId: string) =>
  isFullPath(adUnitSlotId) ? adUnitSlotId.split('/').pop() || '' : adUnitSlotId

const getAdsProps = ({ adUnitSlotId, type, url }: Ads) => ({
  fullPath: getFullPath({ adUnitSlotId, url }),
  slotId: getSlotId(adUnitSlotId),
  sizeMapping: defineAdSizing(type),
})

export const isAnchor = (adUnitSlotId: string) => adUnitSlotId.includes('_anchor')

const defineAnchorSlot = ({ adUnitSlotId, url }: Ads) => {
  const fullPath = getFullPath({ adUnitSlotId, url })
  const slot = window.googletag.defineOutOfPageSlot(
    fullPath,
    window.googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR,
  )

  if (!slot) {
    console.log('Error Anchor Ads', fullPath)
  }

  return slot
}

export const initAdSlot = ({ adUnitSlotId, type, url }: Ads) => {
  loadScriptGPTAds()
  readyAdvertising(() => {
    const defineAd = isAnchor(adUnitSlotId) ? defineAnchorSlot : defineSlot
    const slot = defineAd({ adUnitSlotId, type, url })
    const slotId = getSlotId(adUnitSlotId)

    if (!slot) {
      return
    }

    slot
      .setTargeting('refresh', 'true')
      .setTargeting('refreshed_slot', 'false')
      .addService(window.googletag.pubads())

    window.gptadslots[slotId] = slot
    window.googletag.display(slot)
  })
}
