import cx from 'classnames'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useMedia } from '@/legacy/hooks/useMedia'

import { Ads, type AdsProps } from './Ads'
import type { MediaQueries } from './Advertising.model'
import { destroyAdSlot, initAdSlot, isAnchor } from './utils'

interface AdvertisingProps extends AdsProps {
  mediaQuery?: keyof MediaQueries | null
  classNameWrapper?: string
}

const mediaQueries: MediaQueries = {
  onlyMobile: '(max-width: 959px)',
  onlyDesktop: '(min-width: 960px)',
}

const Advertising = ({
  adUnitSlotId,
  type,
  classNameWrapper,
  mediaQuery,
  ...adsProps
}: AdvertisingProps) => {
  const { asPath } = useRouter()
  const mediaQueryMap = (mediaQuery && mediaQueries[mediaQuery]) || ''
  const isMatchingMedia = useMedia(mediaQueryMap)
  const enabled = mediaQueryMap ? isMatchingMedia : true

  useEffect(() => {
    if (!enabled) {
      return
    }

    initAdSlot({ adUnitSlotId, type, url: asPath })

    return () => {
      destroyAdSlot(adUnitSlotId)
    }
  }, [adUnitSlotId, enabled, type, asPath])

  if (isAnchor(adUnitSlotId)) {
    return null
  }

  return (
    <div className={cx(classNameWrapper, { 'et-d-none': !enabled })}>
      <Ads adUnitSlotId={adUnitSlotId} type={type} {...adsProps} />
    </div>
  )
}

export default Advertising
