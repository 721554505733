import { PRODUCT_KEYS } from '../Products/constants'

const adsSizeTop = [
  [
    [0, 0],
    [
      [320, 50],
      [320, 100],
    ],
  ],
  [
    [980, 200],
    [
      [728, 90],
      [970, 90],
    ],
  ],
]
const adsSizeMiddle = [
  [
    [0, 0],
    [
      [300, 50],
      [300, 250],
      [320, 50],
      [320, 100],
    ],
  ],
  [
    [980, 200],
    [
      [300, 250],
      [320, 100],
      [336, 280],
    ],
  ],
]
const adsSizeBottom = [
  [
    [0, 0],
    [
      [336, 280],
      [300, 250],
      [320, 100],
      [320, 50],
    ],
  ],
  [
    [980, 200],
    [
      [970, 250],
      [970, 90],
      [728, 90],
    ],
  ],
]

const adsSizeArroba = [
  [
    [0, 0],
    [
      [336, 280],
      [300, 250],
    ],
  ],
  [
    [980, 200],
    [
      [970, 250],
      [970, 90],
      [728, 90],
    ],
  ],
]

export const ADS_SIZE_MAP = {
  intercontent_top: adsSizeTop,
  intercontent_middle: adsSizeMiddle,
  intercontent_bottom: adsSizeBottom,
  leaderboard_top: adsSizeTop,
  leaderboard: adsSizeBottom,
  arroba: adsSizeArroba,
}

export const SCRIPT_ID = 'advertising-script'

export const PARENT_AD_UNIT = {
  [PRODUCT_KEYS.serasa]: 'serasa_ensina',
  [PRODUCT_KEYS.credito]: 'credito_blog',
  [PRODUCT_KEYS.ecred]: 'ecred_blog',
  [PRODUCT_KEYS.premium]: 'premium_blog',
  [PRODUCT_KEYS.lno]: 'lno_blog',
  [PRODUCT_KEYS.score]: 'score_blog',
  [PRODUCT_KEYS.carteira]: 'carteira-digital_blog',
}
