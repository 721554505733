import { BuilderComponent } from '@builder.io/react'
import type { BuilderContent } from '@builder.io/sdk'

import { BUILDER_ADS_MODEL, BUILDER_ADS_QUERY } from '@/legacy/config/builder'
import type { AdsPosition } from '@/legacy/models/Advertising'

interface AdvertisingProps {
  adsContent: BuilderContent
  position?: AdsPosition
}

export const BuilderAds = ({ adsContent, position = 'bottom' }: AdvertisingProps) => (
  <BuilderComponent
    model={BUILDER_ADS_MODEL}
    options={{ query: BUILDER_ADS_QUERY[position] }}
    content={adsContent}
  />
)
