import { BuilderComponent } from '@builder.io/react'

import { BUILDER_PAGE_MODEL } from '@/legacy/config/builder'
import type { BuilderPageModel } from '@/legacy/models'

interface PageProps {
  content: BuilderPageModel
}

export default function PageLayout({ content }: PageProps) {
  return (
    <BuilderComponent
      model={BUILDER_PAGE_MODEL}
      options={{ includeRefs: true }}
      {...(content && { content })}
    />
  )
}
